import styles from './Product.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerType } from 'behavior/user/constants';
import { UseSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { makeRichText } from 'utils/render';
import { useIsProductionItem } from 'utils/hooks/orlight';
import DueInDate from 'components/objects/productList/DueInDate';
import useShowDueInDate from 'components/objects/productList/useShowDueInDate';

const ProductStock = ({ 
  inventory, 
  stockLevels, 
  customerType, 
  onRendered, 
  forVariant,
  routingNo,
  children,
  product,
}) => {

  if (!(!!stockLevels)) {
    return null;
  }

  let productId = (product && product.id) ? product.id : undefined;

  const isProductionItem = useIsProductionItem({ routingNo, id: productId });

  const { outOfStock, lowStock, maxStockNumber } = stockLevels;
  let className = '';
  let sanaTextsToLoad = [];
  const showDueInDate = useShowDueInDate({ product });
  const stockValuePlaceholder = <Placeholder className={styles.stockValuePlaceholder} />;

  if (typeof inventory !== 'undefined') {
    let textKey = 'StockText_';

    if (isProductionItem) {
      textKey += 'ProductionItem';
      className = styles.inStock;
    }
    else if (inventory <= outOfStock) {
      textKey += 'OutOfStock';
      className = styles.outStock;
    }
    else if (inventory > lowStock) {
      textKey += 'InStock';
      className = styles.inStock;
    }
    else {
      textKey += 'LowStock';
      className = styles.lowStock;
    }

    if (!customerType || customerType === CustomerType.B2C)
      textKey += '_B2C';
    else
      textKey += '_B2B';

    if (forVariant)
      textKey += '_Variant';

    sanaTextsToLoad = [textKey];

    if (maxStockNumber && maxStockNumber < inventory)
      sanaTextsToLoad.push('StockText_MaxStockNumber');
  } else {
    return stockValuePlaceholder;
  }

  if (showDueInDate) {
    return (<DueInDate product={product} />);
  }
  
  return (
    <UseSanaTexts options={sanaTextsToLoad} placeholder={stockValuePlaceholder}>
      {([stockText, maxStockNumberText]) => {
        onRendered && onRendered();
        return (stockText && (
            <span className={`${styles.stockValue} ${className}`}>
              {makeRichText(stockText.replace('[STOCKAMOUNT]', maxStockNumberText ? maxStockNumberText : inventory), [maxStockNumber])}
              {children}
            </span>
        ));
      }}
    </UseSanaTexts>
  );
};

ProductStock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  additionalClass: PropTypes.string,
  customerType: PropTypes.string,
  onRendered: PropTypes.func,
  forVariant: PropTypes.bool,
  routingNo: PropTypes.string,
  /**
   * if the product is provided, it will display the DueInDate
   */
  product: PropTypes.any,
  children: PropTypes.element,
};

export default connect(({ user: { customerType } }) => ({ customerType }))(ProductStock);
